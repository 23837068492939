const Constants = {
  eventType: {
    onDemand: "ON_DEMAND",
    hybrid: "HYBRID",
    inPerson: "IN_PERSON",
    materialsOnly: "MATERIALS_ONLY",
    practiceVirtual: "PRACTICE_VIRTUAL",
    virtual: "VIRTUAL",
    assessmentOnly: "ASSESSMENT_ONLY",
  },
  myEventType: {
    myCreated: "MY_CREATED",
    myRedeemed: "MY_REDEEMED",
  },
};

export default Constants;
